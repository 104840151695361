import React from 'react'
import handImgSrc from '../assets/FortuneTeller/hand.svg'
import branchImgSrc from '../assets/FortuneTeller/branch.svg'
import logoImgSrc from '../assets/FortuneTeller/logo.svg'

import '../styles/components/fortuneTellerImageTemplate.css'

const FortuneTellerImageTemplate = (props) => {
  const { pageContext } = props
  return (
    <div className="fortuneWrapper">
      <div className="topSection">
        <img className="branch left" src={branchImgSrc} />
        <img className="logo" src={logoImgSrc} />
        <img className="branch right" src={branchImgSrc} />
      </div>
      <div className="middleSection">
        <div className="fortuneText">{pageContext.text}</div>
      </div>
      <div className="bottomSection">
        <img className="hand" src={handImgSrc} />
      </div>
    </div>
  )
}

export default FortuneTellerImageTemplate
